<template>
  <div class="no-data-container">
    <div class="no-data-content w-full sm:w-2/3 lg:w-1/2">
      <div class=""><img src="/img/noData.png" alt="" /></div>
      <p class="message">The link you followed has exprired</p>
    </div>
  </div>
</template>
<script>
export default {};
</script>
<style lang="scss" scoped>
.no-data-container {
  background: rgba(60, 72, 88, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  width: 100%;
  padding: 24px;
}
.no-data-content {
  background: linear-gradient(
    180deg,
    #edfdff 41.98%,
    #f5faf1 139.19%,
    #f7fffd 239.63%
  );
  border-radius: 33px;
  padding: 24px 48px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
.message {
  font-weight: 500;
  font-size: 40px;
  line-height: 152.2%;
  text-align: center;
  color: #000000;
}
</style>

<template>
  <div class="m-loader">
    <div class="loading"></div>
  </div>
</template>
<script>
export default {};
</script>
<style lang="scss" scoped>
.m-loader {
  position: fixed;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}
.loading {
  background: url("../../../public/img/loading.svg") no-repeat;
  width: 52px;
  height: 52px;
  animation: spin 1s linear infinite;
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>

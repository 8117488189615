<template>
  <div>
    <div class="pop-up">
      <div class="w-full sm:w-1/2 lg:w-2/3">
        <img src="/img/surveySubmitted.png" alt="" />
      </div>
    </div>
  </div>
</template>
<script>
export default {};
</script>
<style lang="scss" scoped>
.pop-up {
  position: fixed;
  background-color: rgba(0, 0, 0, 0.1);
  inset: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>

import { render, staticRenderFns } from "./SubmittedSurveyPage.vue?vue&type=template&id=2e3c3244&scoped=true&"
import script from "./SubmittedSurveyPage.vue?vue&type=script&lang=js&"
export * from "./SubmittedSurveyPage.vue?vue&type=script&lang=js&"
import style0 from "./SubmittedSurveyPage.vue?vue&type=style&index=0&id=2e3c3244&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2e3c3244",
  null
  
)

export default component.exports
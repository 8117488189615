<template>
  <div class="error-container">
    <div class="error-popup">
      <div class="flex items-center">
        <div class="w-10">
          <img src="/img/error.png" alt="" />
        </div>
        <span class="font-medium text-[18px] leading-10 ml-2"
          >An error occurred during processing, please check again
        </span>
      </div>
      <div class="popup-footer">
        <md-button @click="$emit('closePopUp')" class="md-raised md-accent"
          >Close</md-button
        >
      </div>
    </div>
  </div>
</template>
<script>
export default {};
</script>
<style scoped>
.error-container {
  background-color: rgba(0, 0, 0, 0.4);
  position: fixed;
  inset: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
}
.error-popup {
  padding: 24px 24px 0 24px;
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.2), 0px 1px 18px rgba(0, 0, 0, 0.12),
    0px 6px 10px rgba(0, 0, 0, 0.14);
}
.popup-footer {
  border-top: 2px solid #ccc;
  margin-top: 24px;
  display: flex;
  justify-content: center;
  padding: 4px 0;
}
</style>

<template>
  <div class="md-layout main-content">
    <div class="md-layout-item md-size-100">
      <img alt="" class="w-full" src="/img/banner.png" />
    </div>
    <div
      v-if="questions.length"
      class="md-layout-item md-layout list-question px-4 py-2 mb-4"
    >
      <div class="md-layout-item md-size-100 md-layout">
        <div class="md-layout-item md-size-100 md-xsmall-size-100">
          <p class="main-title px-4 py-2">{{ gerneral_information.title }}</p>
          <p
            class="sub-title px-4 py-2"
            v-html="gerneral_information.description"
          ></p>
        </div>
      </div>
      <div class="md-layout-item md-size-100 px-4 py-2">
        <span class="text-red font-medium">Required *</span>
      </div>
      <div class="md-layout-item md-size-100 md-layout">
        <div
          v-for="(question, index) in questions"
          :key="'question' + index"
          :class="
            question.require &&
            checkNoAnswer(question) &&
            hasError &&
            'red-border'
          "
          class="md-layout-item md-size-100 md-layout px-10 question"
        >
          <div class="question-text flex">
            <span
              :class="{ 'mark-required': question.require }"
              style="margin-right: 10px"
              >{{ index + 1 }}.</span
            >
            <div v-html="question.question"></div>
          </div>
          <div
            v-if="question.subText"
            class="md-layout-item md-size-100 question-subtext"
          >
            {{ question.subText }}
          </div>
          <div
            v-if="question.type === QUESTIONTYPE.checkbox"
            class="md-layout-item md-size-100"
          >
            <div
              v-for="(choice, index) in question.choices"
              :key="'checkbox' + index"
            >
              <md-checkbox v-model="question.answer" :value="choice.text"
                >{{ choice.text }}
              </md-checkbox>
            </div>
          </div>
          <div
            v-if="question.type === QUESTIONTYPE.radio"
            class="md-layout-item md-size-100"
          >
            <div
              v-for="(choice, index) in question.choices"
              :key="'radio' + index"
            >
              <md-radio v-model="question.answer" :value="choice.text"
                >{{ choice.text }}
              </md-radio>
            </div>
          </div>
          <div
            v-if="question.type === QUESTIONTYPE.dropdown"
            class="md-layout-item md-size-100"
          >
            <md-field class="max-w-[350px]">
              <md-select v-model="question.answer">
                <md-option
                  v-for="(choice, index) in question.choices"
                  :key="'dropdown' + index"
                  :value="choice.text"
                  >{{ choice.text }}
                </md-option>
              </md-select>
            </md-field>
          </div>
          <div
            v-if="question.type === QUESTIONTYPE.singleLineText"
            class="md-layout-item md-size-100"
          >
            <md-field>
              <md-input v-model="question.answer" class="bg-[#fff]"></md-input>
            </md-field>
          </div>
          <div
            v-if="question.type === QUESTIONTYPE.multiLineText"
            class="md-layout-item md-size-100"
          >
            <md-field class="bg-[#fff]">
              <md-textarea v-model="question.answer"></md-textarea>
            </md-field>
          </div>
          <p
            v-if="question.require && checkNoAnswer(question) && hasError"
            class="text-red text-sm px-4 pb-2"
          >
            This question is required
          </p>
          <p
            v-if="
              question.type === QUESTIONTYPE.singleLineText &&
              question.number_only &&
              !checkInputValidate(question.answer) &&
              hasError
            "
            class="text-red text-sm px-4 pb-2"
          >
            This answer is invalid
          </p>
        </div>
      </div>
      <div class="md-layout-item md-size-100 flex justify-center py-2">
        <md-button
          class="md-raised text-[#fff] bg-[#4caf50]"
          @click="sendSurvey"
          >Send survey
        </md-button>
      </div>
    </div>
    <div v-else class="md-layout-item md-size-100">
      <NoDataResponse></NoDataResponse>
    </div>
    <Loading v-if="isLoading"></Loading>
    <ErrorPopUp v-if="apiError" @closePopUp="closePopUp"></ErrorPopUp>
  </div>
</template>
<script>
let url = process.env.VUE_APP_API_URL;
import Loading from "@/components/Bases/Loading";
import NoDataResponse from "./NoDataResponse";
import ErrorPopUp from "./ErrorPopUp";

export default {
  components: {
    Loading,
    NoDataResponse,
    ErrorPopUp,
  },
  data() {
    const QUESTIONTYPE = {
      dropdown: "drop_down_list",
      radio: "radio_button",
      checkbox: "checkbox",
      singleLineText: "single_line_text",
      multiLineText: "multi_line_text",
    };
    return {
      QUESTIONTYPE,
      hasData: false,
      questions: [],
      id: "",
      gerneral_information: "",
      hasError: false,
      isLoading: true,
      anonymous: false,
      apiError: false,
    };
  },
  created() {
    this.fetchSurvey();
    this.updateTitle();
  },
  computed: {
    surveyId() {
      return this.$route.params.surveyId;
    },
    surveyHash() {
      return this.$route.params.surveyHash;
    },
  },
  methods: {
    closePopUp() {
      this.apiError = false;
    },
    updateTitle() {
      document.title = this.gerneral_information.title || "EQuest survey";
    },
    checkNoAnswer(question) {
      return (
        (question.type === this.QUESTIONTYPE.checkbox &&
          !question.answer.length) ||
        (question.type !== this.QUESTIONTYPE.checkbox && !question.answer)
      );
    },
    checkInputValidate(value) {
      if (!value) return true;
      return /^\d+$/.test(value.trim());
    },
    fetchSurvey() {
      this.isLoading = true;
      this.$axios
        .get(
          `${url}/school-service/public/survey/${this.surveyId}/${this.surveyHash}`
        )
        .then((response) => {
          this.anonymous =
            response.data.gerneral_information.appearance_report_setting;
          this.questions = response.data.survey_structure.questions.map(
            (question) => {
              let answer = "";
              if (question.type === this.QUESTIONTYPE.checkbox) {
                answer = [];
                if (question.defaultChoice >= 0) {
                  answer.push(question.choices[question.defaultChoice].text);
                }
              } else {
                if (question.defaultChoice >= 0) {
                  answer = question.choices[question.defaultChoice].text;
                }
              }
              return {
                ...question,
                answer: answer,
              };
            }
          );
          this.id = response.data.id || response.data._id;
          this.gerneral_information = response.data.gerneral_information;
          this.hasData = true;
          this.updateTitle();
        })
        .catch(() => {
          this.hasData = false;
          this.questions = [];
        })
        .finally(() => (this.isLoading = false));
    },
    sendSurvey() {
      const messageError = [];
      this.questions.forEach((item) => {
        messageError.push(
          (item.require && this.checkNoAnswer(item)) ||
            (item.number_only && !this.checkInputValidate(item.answer))
        );
      });
      this.hasError = messageError.some((item) => item);
      if (this.hasError) return;
      let data = this.questions.map(({ question, type, answer }) => ({
        question,
        type,
        answer,
      }));
      let body = {
        data,
        survey_id: this.surveyId,
        hash_code: this.surveyHash,
        anonymous: this.anonymous,
      };
      this.$axios
        .post(`${url}/school-service/public/survey`, body)
        .then(() => {
          this.$router.replace({ name: "SubmittedSurveyPage" });
        })
        .catch(() => {
          this.apiError = true;
        });
    },
  },
};
</script>
<style scoped>
.md-field .md-input {
  min-width: 0 !important;
}

.main-content {
  position: relative;
  margin: 0 auto;
  background-color: #fff;
  width: 100%;
}

.list-question {
  position: absolute;
  width: 80%;
  max-width: 1024px !important;
  top: 80%;
  left: 50%;
  transform: translateX(-50%);
  background-color: #fff;
  border-radius: 8px;
  margin-bottom: 12px;
  box-shadow: 0 0 10px 10px #c3c3c3;
}

.main-title {
  font-weight: bold;
  font-size: 24px;
  text-transform: capitalize;
}

.sub-title {
  font-size: 16px;
  color: #1a191c;
}

.text-red {
  color: #f44336;
}

.question {
  margin: 12px 0 !important;
  background: rgba(170, 170, 170, 0.08);
  border: 2px dashed rgba(102, 102, 102, 0.4);
}

.question-text {
  font-size: 16px;
  line-height: 40px;
  color: #121212;
}

.question-subtext {
  font-size: 14px;
}

.mark-required::after {
  content: "*";
  color: red;
}
</style>
